<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>발주관리 {{ isNew ? '등록' : '수정' }}</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>발주정보 <common-checkbox-yn
            class="mgl10"
            style="display:inline-block"
            :default-checked="false"
            v-model.trim="ordInfo.adjtYn"
            :label="'정산필요'"
        ></common-checkbox-yn> </h2>
        
      </div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <common-multi-drop-down
                    ref="cmpyCdRef"
                    :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
                    v-model="ordInfo.cmpyCd"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                    :data-value-field="'cmpyCd'"
                    :data-text-field="'cmpyNm'"
                    @change="onChangeCmpyCd"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ ordInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>회사코드</label>
            <ul>
              <li>{{ ordInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>납품로케이션</label>
            <ul>
              <li v-if="isNew || ordInfo.isLocaEditAble">
                <common-multi-drop-down
                    ref="locaCdRef"
                    :disabled="!ordInfo.cmpyCd"
                    v-model="ordInfo.locaCd"
                    :params="{ searchCmpyCd : ordInfo.cmpyCd }"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
                    :data-value-field="'locaCd'"
                    :data-text-field="'locaNm'"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ordInfo.locaNm}}</li>
            </ul>
          </dd>
          <dd><label>발주일자<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li>
                <kendo-datepicker
                    ref="ordDtRef"
                    v-model="ordInfo.ordDtVModel"
                    :format="'yyyy/MM/dd'"
                    :placeholder="'년/월/일'"
                ></kendo-datepicker>
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>입고예정 정보
          <button class="mid_btn orange fr mr10" @click="addInSchedule">발주차수 추가</button>
        </h2>
      </div>
      <common-grid-custom
          ref="userMngGridRef"
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :data-source="inScheduleList"
          :columns="inScheduleGridColumns"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
        <template #itemCd="{rowItem}" >
          <span v-if="rowItem.data.useYn === 'N' || rowItem.data.inStatusCd !== constant.inStatus.yet"> {{ rowItem.data.itemNm }} </span>
          <span v-else-if="rowItem.data.inStatusCd === constant.inStatus.yet">
            <span >
              <common-multi-drop-down
                  v-if="!!rowItem.data.itemCd || rowItem.data.isNew"
                  :ref="`itemCdRef${rowItem.index}`"
                  :params="{ searchActiveYn : 'Y', searchCmpyCd : ordInfo.cmpyCd}"
                  :disabled="!ordInfo.cmpyCd || rowItem.data.disable"
                  v-model="rowItem.data.itemCd"
                  :url="`${ApiConfig.efsDomain}/efs/drop-down/item`"
                  :data-value-field="'itemCd'"
                  :data-text-field="'itemNm'"
                  :class="'w100'"
                  @change="e=>{
                    rowItem.data.goodsCd = null
                    $refs[`goodsCdRef${rowItem.index}`].widget().value('')
                  }"
              >
              </common-multi-drop-down>
            </span>
          </span>
        </template>
        <template #goodsCd="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N' || rowItem.data.inStatusCd !== constant.inStatus.yet"> {{ rowItem.data.goodsNm }} </span>
          <span v-else-if="rowItem.data.inStatusCd === constant.inStatus.yet">
            <span>
              <common-multi-drop-down
                  :ref="`goodsCdRef${rowItem.index}`"
                  :params="{ searchActiveYn : 'Y', searchItemCd : rowItem.data.itemCd, searchCmpyCd : ordInfo.cmpyCd }"
                  v-model="rowItem.data.goodsCd"
                  :disabled="!rowItem.data.itemCd || rowItem.data.disable"
                  :url="`${ApiConfig.efsDomain}/efs/drop-down/goods`"
                  :data-value-field="'goodsCd'"
                  :data-text-field="'goodsNm'"
                  :class="'w100'"
                  @change="value=>{
                    if(!ordInfo.supCmpyCd){
                      getGoodSupCmpyUser(value)
                    }else {
                      validatorSupCmpyCd(rowItem)
                    }
                  }"
              >
              </common-multi-drop-down>
            </span>
          </span>
        </template>
        <template #ordNos="{rowItem}">
          <span v-if="rowItem.data.isNew">{{ rowItem.index + 1 }}차수</span>
          <span v-else>{{ rowItem.data.ordNos }}차수</span>
        </template>
        <template #inExpectDate="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N'"> - </span>
          <span v-else>
            <span v-show="rowItem.data.inStatusCd !== constant.inStatus.yet">
              {{ rowItem.data.inExpectDate }}
            </span>
            <span v-show="rowItem.data.inStatusCd === constant.inStatus.yet">
              <kendo-datepicker
                  :ref="`inExpectDateRef${rowItem.index}`"
                  v-model="rowItem.data.inExpectDateVModel"
                  :disabled="rowItem.data.disable"
                  :format="'yyyy/MM/dd'"
                  :placeholder="'년/월/일'"
                  :class="'w100'"
              ></kendo-datepicker>
            </span>
          </span>
        </template>
        <template #supCnt="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N'"> 0 </span>
          <span v-else class="k-rtl">
            <span v-show="rowItem.data.inStatusCd !== constant.inStatus.yet">
              {{ rowItem.data.supCnt | comma}}
            </span>
            <span v-show="rowItem.data.inStatusCd === constant.inStatus.yet">
              <kendo-numerictextbox
                  :ref="`supCntRef${rowItem.index}`"
                  :class="'w80'"
                  :disabled="rowItem.data.disable"
                  :format="'###,#'"
                  :min="0"
                  :max="9999"
                  v-model="rowItem.data.supCnt">
              </kendo-numerictextbox>
            </span>
          </span>
          EA
        </template>
        <template #supPrice="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N'"> 0 </span>
          <span v-else class="k-rtl">
            <span v-show="rowItem.data.inStatusCd !== constant.inStatus.yet">
              {{ rowItem.data.supPrice | comma }}
            </span>
            <span v-show="rowItem.data.inStatusCd === constant.inStatus.yet">
              <kendo-numerictextbox
                  :ref="`supPriceRef${rowItem.index}`"
                  :class="'w80'"
                  :format="'###,#'"
                  :disabled="rowItem.data.disable"
                  :step="10"
                  :min="0"
                  :max="99999999999"
                  v-model="rowItem.data.supPrice">
              </kendo-numerictextbox>
            </span>
          </span>
          원
        </template>
        <template #supTotPrice="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N'"> 0 </span>
          <span v-else class="k-rtl">
            <span v-show="rowItem.data.inStatusCd !== constant.inStatus.yet">
              {{ rowItem.data.supTotPrice | comma }}
            </span>
            <span v-show="rowItem.data.inStatusCd === constant.inStatus.yet">
              <kendo-numerictextbox
                  :ref="`supTotPriceRef${rowItem.index}`"
                  :class="'w80 k-rtl'"
                  :format="'###,#'"
                  :disabled="true"
                  :min="0"
                  :spinners="false"
                  :value="rowItem.data.supCnt * rowItem.data.supPrice">
              </kendo-numerictextbox>
            </span>
          </span>
          원
        </template>
        <template #contents="{rowItem}">
          <span v-if="rowItem.data.useYn === 'N'"> {{ rowItem.data.contents }} </span>
          <span v-else>
            <span v-if="rowItem.data.inStatusCd !== constant.inStatus.yet">{{ rowItem.data.contents }}</span>
            <input
                v-else
                v-model="rowItem.data.contents"
                :disabled="rowItem.data.disable"
                :class="'w100'"
            />
          </span>
        </template>
        <template #deleteBtn="{rowItem}">
          <button v-if="rowItem.data.isNew"
                  @click="deleteMngCmpy(rowItem)"
                  class="small_btn">삭제</button>
          <button v-else-if="!rowItem.data.isNew && rowItem.data.useYn==='Y' && rowItem.data.inStatusCd === constant.inStatus.yet"
                  @click="deleteMngCmpy(rowItem)"
                  class="small_btn">제외</button>
        </template>
      </common-grid-custom>

    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>공급업체정보</h2></div>
      <common-grid-custom
          ref="userMngGridRef"
          style="cursor: pointer; "
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :sortable-mode="'multiple'"
          :data-source="supCmpyUserList"
          :columns="supCmpyUserGridColumns"
          :selectable="'row'"
      >
        <template #radio="{rowItem}">
          <radio-group
            :name="'recevEmail'"
            v-model="ordInfo.supMngUnqUserId"
            :data-items="[{value : rowItem.data.supMngUnqUserId, label : ''}]"
            @click="onClickSupMngUser(rowItem.data)"
          ></radio-group>
        </template>

      </common-grid-custom>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickOrdInfoSave">저장</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import Constant from '@/common/constant'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'

export default {
  name: 'OrdManageSave',
  watch : {
    inScheduleList : function (newValue, oldValue){
      const vm = this
      if(newValue.length < 1){
        vm.ordInfo.supCmpyCd = null
        vm.supCmpyUserList = []
      }
    }
  },
  created () {
    if (!!this.$route.params.ordNo) {
      this.isNew = false
      this.getOrdInfo()
    }else {
      // 새로등록하는 경우 입고예정정보 목록에 입고상태 컬럼 삭제
      this.isNew = true
      this.inScheduleGridColumns.forEach((item, i)=>{
        if(item.field === 'inStatusNm'){
          this.inScheduleGridColumns.splice( i, 1)
        }
      })
    }
  },
  methods: {
    onChangeCmpyCd : function (){
      const vm = this

      if( !!vm.preCmpyCd && vm.preCmpyCd !== vm.ordInfo.cmpyCd){
        kendo.confirm('회사를 변경하면 입고예정정보와 공급처체 정보가 모두 초기화됩니다. 변경하시겠습니까?').done(function (){
          vm.resetData()
        }).fail(e=>{
          vm.ordInfo.cmpyCd = vm.preCmpyCd
          vm.$refs.cmpyCdRef.widget().value(vm.preCmpyCd)
        })
      }else {
        vm.preCmpyCd =vm.ordInfo.cmpyCd
        vm.ordInfo.locaCd = ''
        vm.$refs.locaCdRef?.widget().value('')
      }
    },
    resetData : function (){
      const vm = this

      vm.ordInfo.locaCd = ''
      vm.$refs.locaCdRef?.widget().value('')

      vm.inScheduleList = []
      vm.supCmpyUserList =[]
      vm.ordInfo.supCmpyCd = null
      vm.preCmpyCd = vm.ordInfo.cmpyCd
    },
    deleteMngCmpy(rowItem) {
      const vm = this

      let existGoodsCd = false
      if(rowItem.data.isNew){
        vm.inScheduleList.splice(rowItem.index,1)
        vm.inScheduleList.forEach((item, i)=>{

          vm.$refs['itemCdRef'+i]?.widget()?.value(item.itemCd ?? '')
          vm.$refs['goodsCdRef'+i]?.widget()?.value(item.goodsCd ?? '')
          vm.$refs['inExpectDateRef'+i]?.kendoWidget()?.value(item.inExpectDateVModel ?? '')
          if(item.goodsCd){
            existGoodsCd = true
          }
        })

        if(!existGoodsCd){
          vm.ordInfo.supCmpyCd = null
          vm.supCmpyUserList = []
        }
      }else {
        rowItem.data.useYn = 'N'
        rowItem.data.rowClass = 'bg_g '
        rowItem.data.supCnt = 0
        rowItem.data.supPrice = 0
        rowItem.data.inStatusNm = '제외'
        rowItem.data.disable = true
        this.$set(vm.inScheduleList, rowItem.index, rowItem.data)
      }


      // console.log(temp)
      // vm.inScheduleList = []
      // vm.inScheduleList = temp
      // vm.inScheduleList.forEach((item, i)=>{
      //   // if(!item.itemCd || item.itemCd === ''){
      //   //   vm.$refs['itemCdRef'+i].widget().value('')
      //   // }
      //   // if(!item.goodsCd || item.goodsCd === ''){
      //   //   vm.$refs['goodsCdRef'+i].widget().value('')
      //   // }
      // })
    },
    addInSchedule : function () {
      const vm = this

      if(vm.inScheduleList.length >= 5){
        kendo.alert("5차수를 초과하여 추가할 수 없습니다.")
      }else {
        this.$set(vm.inScheduleList, vm.inScheduleList.length++, {
          itemCd : null,
          goodsCd : null,
          inExpectDate : null,
          supCnt : 0,
          supPrice : 0,
          supTotPrice : 0,
          ordStatus : null,
          contents : null,
          isNew : true, // 새로 추가하고있는경우 true
          inStatusCd : vm.constant.inStatus.yet,
          useYn : 'Y',
          disable : false, // 수정이 불가한 경우 true(수정을 통해 미입고상태를 삭제한 경우)
        })
      }
    },
    getOrdInfo: function () {
      const vm = this
      ApiUtil.query(
          `${ApiConfig.efsDomain}/efs/manage/ord/${vm.$route.params.ordNo}`)
      .then(function (response) {
        if (response.data.resultStatus.messageCode === '2000') {
          vm.ordInfo = response.data.resultData
          vm.ordInfo.ordDtVModel = EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(vm.ordInfo.ordDt))
          vm.ordInfo.recvEmail = response.data.resultData.supMngUnqUserEmail
          vm.inScheduleList = response.data.resultData.ordDetailItemResponseList
          let cntInStatusNotYet = 0
          vm.inScheduleList.forEach((item, i)=>{
            item.isNew = false
            item.inExpectDateVModel = EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(item.inExpectDate))
            if(item.useYn === 'N') {
              item.rowClass = 'bg_g '
              item.inStatusNm = '제외'
            }
            item.disable = [vm.constant.inStatus.complete, vm.constant.inStatus.ing].includes(item.inStatusCd)
            if(item.disable) cntInStatusNotYet++
          })
          vm.ordInfo.isLocaEditAble = cntInStatusNotYet === 0
          vm.getGoodSupCmpyUser(vm.inScheduleList[0].goodsCd)
        } else {
          kendo.alert(response.data.resultStatus.messageText)
        }
      },
      )
    },
    onClickSupMngUser (data){
      const vm = this
      vm.ordInfo.recvEmail = data.supMngUnqUserEmail
    },
    onClickOrdInfoSave () {
      const vm = this
      if (vm.validator()) {
         kendo.confirm('저장 하시겠습니까?').done(
             function () {
               vm.ordInfoSave()
             })
      }
    },
    ordInfoSave() {
      const vm = this

      if (vm.isNew) {
        vm.inScheduleList.forEach((item, i)=>{
          item.ordNos = i+1
          item.inExpectDate = EtnersDateTimeUtil.dateToYYYYMMDD(item.inExpectDateVModel)
        })
        let param = {
          ...vm.ordInfo,
          ordDt : EtnersDateTimeUtil.dateToYYYYMMDD(vm.ordInfo.ordDtVModel),
          ordItemDetailRequestParamList : vm.inScheduleList
        }
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/ord`, param)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'OrdManageDetail',
            params: {
              ordNo: response.data.resultData.ordNo,
            }
          })
        })
      } else {
        vm.inScheduleList.forEach((item, i)=>{
          item.ordNos = item.ordNos ?? i + 1
          item.inExpectDate = EtnersDateTimeUtil.dateToYYYYMMDD(item.inExpectDateVModel)
        })
        let param = {
          ...vm.ordInfo,
          ordDt : EtnersDateTimeUtil.dateToYYYYMMDD(vm.ordInfo.ordDtVModel),
          ordItemDetailRequestParamList : vm.inScheduleList
        }
        ApiUtil.put(
            `${ApiConfig.efsDomain}/efs/manage/ord/${vm.$route.params.ordNo}`, param)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'OrdManageDetail',
            params: {
              ordNo: vm.$route.params.ordNo,
            }
          })
        })
      }
    },
    getGoodSupCmpyUser : function (goodsCd){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/ord/goods-cmpy`, {goodsCd})
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.supCmpyUserList = response.data.resultData
              vm.ordInfo.supCmpyCd = vm.supCmpyUserList[0].supCmpyCd
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    validatorSupCmpyCd : function (rowItem){
      const vm = this
      let rowItemSupCmpyCd = vm.$refs[`goodsCdRef${rowItem.index}`].widget().dataItem().supCmpyCd
      if(rowItemSupCmpyCd !== vm.ordInfo.supCmpyCd){
        kendo.alert('공급업체가 다른 상품은 선택할 수 없습니다.').bind('close', function () {
          rowItem.data.goodsCd = null
          vm.$refs[`goodsCdRef${rowItem.index}`].widget().value('')
          vm.$refs[`goodsCdRef${rowItem.index}`].widget().focus()
          vm.$refs[`goodsCdRef${rowItem.index}`].widget().toggle()
        })
      }
    },
    validator () {
      const vm = this
      // return true
      if (!vm.ordInfo.cmpyCd) {
        kendo.alert('회사를 선택해주세요.').bind('close', function () {
          vm.$refs.cmpyCdRef.widget().focus()
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return
      }
      if (!vm.ordInfo.locaCd) {
        kendo.alert('납품 로케이션을 선택해주세요.').bind('close', function () {
          vm.$refs.locaCdRef.widget().focus()
          vm.$refs.locaCdRef.widget().toggle()
        })
        return
      }
      if (!vm.ordInfo.ordDtVModel) {
        kendo.alert('발주일자를 선택해주세요.').bind('close', function () {
          vm.$refs.ordDtRef.kendoWidget().open()
        })
        return
      }

      if(vm.inScheduleList.length === 0){
        kendo.alert('입고예정 상품을 추가해주세요.')
      }else {
        let existUseYn = false
        vm.inScheduleList.forEach((item,i)=>{
          if(item.useYn === 'Y'){
            existUseYn = true
          }
        })

        if (!existUseYn) {
          kendo.confirm('입고예정 상품이 존재하지 않습니다. 발주정보을 삭제하시겠습니까?').done( function () {
            vm.deleteOrd()
          })
          return
        }
      }

      let result = true

      vm.inScheduleList.every((item,i)=>{
        if(!item.itemCd){
          kendo.alert("품목을 선택해주세요.").bind('close', function (){
            vm.$refs[`itemCdRef${i}`]?.widget().focus()
            vm.$refs[`itemCdRef${i}`]?.widget().toggle()
          })
          result = false
        }
        if(result && !item.goodsCd){
          kendo.alert("상품을 선택해주세요.").bind('close', function (){
            vm.$refs[`goodsCdRef${i}`]?.widget().focus()
            vm.$refs[`goodsCdRef${i}`]?.widget().toggle()
          })
          result = false
        }
        if(result && !item.inExpectDateVModel){
          kendo.alert("입고예정일를 선택해주세요.").bind('close', function (){
            vm.$refs[`inExpectDateRef${i}`]?.kendoWidget().open()
          })
          result = false
        }
        if(result && (!item.supCnt || item.supCnt === 0) && item.useYn === 'Y'){
          kendo.alert(`수량을 입력해주세요.`).bind('close', function (){
            vm.$refs[`supCntRef${i}`]?.kendoWidget().focus()
          })
          result = false
        }
        if(result && (!item.supPrice || item.supPrice === 0) && item.useYn === 'Y'){
          kendo.alert(`공급가를 입력해주세요.`).bind('close', function (){
            vm.$refs[`supPriceRef${i}`]?.kendoWidget().focus()
          })
          result = false
        }
        return result
      })

      if(!result) return false

      if (!vm.ordInfo.supMngUnqUserId || !vm.ordInfo.recvEmail) {
        kendo.alert('발주서 수신자를 선택해주세요.')
        return
      }

      return true
    },
    deleteOrd : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/ord/${vm.$route.params.ordNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'OrdManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
  data: function () {
    const vm = this
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const rightAttributes = { style: 'text-align: right !important;' }
    const centerAttributes = { style: 'text-align: center;'}
    const inScheduleRowItem = {
      itemCd : null,
      goodsCd : null,
      supCnt : 0,
      supPrice : 0,
      supTotPrice : 0,
      ordStatus : null,
      contents : null,
      isNew : true,
      inExpectDateVModel : null,
      inStatusCd : '01',
      disable : false,
      useYn : 'Y'
    }
    return {
      vm: vm,
      Constant,
      searchDate: new Date(),
      constant : {
        inStatus : {
          complete : '03',
          ing : '02',
          yet : '01'
        }
      },
      propsCmpyCd: '',
      propsLocaCd: '',
      isWindowOpen: false,
      isNew: true,
      ApiConfig: ApiConfig,
      supCmpyUserList : [],
      preCmpyCd : null,
      ordInfo: {
        activeYn: 'Y',
        adjtYn : 'N',
        cmpyCd : null
      },
      supUserRowItem : {
        data : {
          supMngUnqUserId : ''
        }
      },
      inScheduleRowItem,
      rowItem : {
        data : inScheduleRowItem,
        index : null
      },
      inScheduleList : [
        inScheduleRowItem
      ],
      supCmpyUserGridColumns : [
      {
        field: 'supCmpyNm',
        title: '공급사',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
      {
        field: 'supMngUnqUserNm',
        title: '성명',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
      {
        field: 'supMngUnqUserPsnScnNm',
        title: '직위',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
      {
        field: 'supMngUnqUserTelNo',
        title: '연락처',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
      {
        field: 'supMngUnqUserEmail',
        title: '이메일',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
      {
        field: 'radio',
        title: '발주서 수신자',
        width: '10%',
        headerAttributes: headerAttributes,
        attributes: centerAttributes
      },
    ],
      inScheduleGridColumns : [
        {
          field: 'itemCd',
          title: '품목',
          width: '15%',
          headerAttributes: headerAttributes,
          attributes: { class:'txt_l' }
        },
        {
          field: 'goodsCd',
          title: '상품명',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: { class:'txt_l' }
        },
        {
          field: 'ordNos',
          title: '입고차수',
          width: '4%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'inExpectDate',
          title: '입고예정일',
          width: '7%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'supCnt',
          title: '수량',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
        },
        {
          field: 'supPrice',
          title: '공급가',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
        },
        {
          field: 'supTotPrice',
          title: '합계',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
        },
        {
          field: 'inStatusNm',
          title: '입고상태',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'contents',
          title: '비고',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'deleteBtn',
          title: '삭제/제외',
          width: '4%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
      stkGoodsList: [],
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'itemNm',
          title: '품목',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'goodsCd',
          title: '상품코드',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'goodsNm',
          title: '상품명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'renewGbnNm',
          title: '상품분류',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cnt',
          title: '재고수량',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return EtnersCommonUtil.numberWithCommas(dataItem.cnt)
          }
        }
      ],
    }
  },
}
</script>

<style scoped>
</style>
